<template>
    <el-dialog :title="title" width="400px" :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="120px" label-position="top" v-loading="loading">
            <el-form-item label="请填写审核建议：" prop="goBackReason">
                <el-input v-model.trim="inputForm.goBackReason" :rows="5" type="textarea" maxlength="100" placeholder="请输入内容（100字内）"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                // 通过驳回
                visible: false,
                loading: false,
                title: '',
                auditType: '', // 0通过，1驳回
                auditIds: [],
                inputForm: {
                    goBackReason: '',
                },
                dataRule: {
                    goBackReason: [
                        {required: true, message: '审核建议不能为空', trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            init(ids, type) {
                this.auditIds = ids
                if(type == 0) {
                    this.title = '资源审核通过'
                    this.auditType = 3
                } else if (type == 1) {
                    this.title = '资源审核驳回'
                    this.auditType = 4
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            // 确定
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.dyyg.appraisalOrGoBack, {
                            goBackReason: this.inputForm.goBackReason,
                            resourceState: this.auditType,
                            resourcesIds: this.auditIds,
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success(this.auditType == 3 ? '通过成功': '驳回成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(this.auditType == 3 ? '通过失败': '驳回失败')
                            }
                            this.loading = false
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
